import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { NavBar } from "../components/header"
import FleetSwiper from "../components/fleetSwiper"
import Newsletter from "../components/newsletter"
import { Footer } from "../components/footer"
import SubscriptionForm from "../components/forms/subscriptionForm"
import { StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import Fade from "react-reveal/Fade"
import Roll from "react-reveal/Roll"

const BookingExperience = () => {
    const allYachtsData = useStaticQuery(graphql`
        query AllYachtsBookAnExperience {
            allYachtsJson(filter: { name: { ne: "default" } }) {
                nodes {
                    id
                    name
                    specification {
                        length
                        beam
                        draft
                        fuel
                        speed
                        berths
                    }
                    model
                    link
                    description {
                        first
                        second
                    }
                    features {
                        first
                        second
                    }
                    mainImage {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
    `)
    const yachts = allYachtsData.allYachtsJson.nodes
    const [yachtData] = React.useState(yachts)

    return (
        <Layout>
            <Seo title="Book an Experience" />

            <div className="top-gradient-container">
                <NavBar image />
                <StaticImage className="bg-contact-water" src="../images/water1.png" alt="Water Background" />
                <div className="container-xxl ">
                    <div className="row ">
                        <div style={{ zIndex: 3 }} className="col-12 col-md-4 order-md-2 position-relative pe-inside">
                            <Roll left>
                                <StaticImage
                                    style={{ zIndex: 3 }}
                                    className="frame-contact-page"
                                    src="../images/frame2.png"
                                    alt="Frame"
                                />
                            </Roll>
                        </div>
                        <Fade cascade delay={700} duration={1500}>
                            <div className="col-12 mb-3 col-md-8 mb-md-0 order-md-1 ps-inside pe-md-5 d-flex flex-column justify-content-center">
                                <h2>Book an Experience</h2>
                                <p>
                                    We are currently working behind the scenes on a digital booking system that will make booking one
                                    of our Luxury yachts simple and easy.
                                </p>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
            <SubscriptionForm title={<h2 className="text-center text-white">Be the first to know</h2>} className="m-100" />
            <div className="helper-fleet">
                <FleetSwiper yachts={yachtData} />
            </div>
            <Newsletter />
            <Footer />
        </Layout>
    )
}

export default BookingExperience
